import { useAbTest } from 'ab-test/hooks/useAbTest/useAbTest'
import { AbTestKeys } from 'types/constants/AbTestKeys'

import {
	FEEDBACK_ADD_TO_CART_VARIANTS,
	mapFeedbackAddToCartVariant,
} from '../utils/mapFeedbackAddToCartVariant'

export function useFeedbackTestVariable() {
	const abTest = useAbTest({
		key: AbTestKeys.FeedbackAddToBag,
		defaultDecision: { enabled: false },
	})

	const variant = abTest?.enabled
		? mapFeedbackAddToCartVariant(abTest.variables)
		: FEEDBACK_ADD_TO_CART_VARIANTS.DEFAULT

	return { variant, enabled: abTest?.enabled }
}
