export const FEEDBACK_ADD_TO_CART_VARIANTS = {
	DEFAULT: 'show_feedback_var0',
	VARIANT_1: 'show_feedback_var1',
	VARIANT_2: 'show_feedback_var2',
} as const

export type FeedbackAddToCartVariant =
	(typeof FEEDBACK_ADD_TO_CART_VARIANTS)[keyof typeof FEEDBACK_ADD_TO_CART_VARIANTS]

export function mapFeedbackAddToCartVariant(
	variables:
		| {
				var1_feedback_add_to_cart?: boolean
				var2_feedback_add_to_cart?: boolean
		  }
		| undefined
): FeedbackAddToCartVariant {
	switch (true) {
		case variables?.var1_feedback_add_to_cart:
			return FEEDBACK_ADD_TO_CART_VARIANTS.VARIANT_1
		case variables?.var2_feedback_add_to_cart:
			return FEEDBACK_ADD_TO_CART_VARIANTS.VARIANT_2
		default:
			return FEEDBACK_ADD_TO_CART_VARIANTS.DEFAULT
	}
}
