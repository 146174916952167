export enum AbTestKeys {
	PlpOneColumnPlus = 'plp_one_column_plus',
	PlpOutletNewness = 'plp_outlet_newness',
	PlpOneColumnLayout = '_plp__mobile_1_column_layout',
	PdpShopTheLook = 'pdp_shop_the_look',
	PromoBanner = 'promo_countdown',
	Test = 'test',
	Shopping_cart_pago_a_plazos = 'shopping_cart_pago_a_plazos',
	ShoppingCartPromoCodes = 'shopping_cart_promotional_codes',
	FeedbackAddToBag = 'shopping_cart_feedback_add_to_cart',
	PlpFirstFourSegmentation = 'plp_first_four_segmentation',
	NewSearchUi = 'search_new_ui',
	PaymentCobrandedCards = 'payments_cobranded_cards',
}
