'use client'

import { conversion } from 'country-configuration/currency'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import {
	type Dispatch,
	type ReactNode,
	createContext,
	useCallback,
	useContext,
	useMemo,
	useState,
} from 'react'

import { PAGE_TYPE_SHOPPING_CART, type STOCK } from '../constants/constants'
import type { PurchaseType } from '../services/graphql.types'

export interface InitialState {
	showPreview: boolean | 'auto'
	openedLimitWarningModal: boolean
	visibleItemErrorBanner: boolean
	cartMaxAmount: string
	showRefundPartners: boolean
	openedAddNoStockModal: boolean
	openedStockModal: boolean
	stockModalState: (typeof STOCK)[keyof typeof STOCK] | null
	stockCart: PurchaseType | null
	preventClose?: boolean | null
	status?: boolean
	smallSnackbar?: boolean
}

const DEFAULT_INITIAL_STATE: InitialState = {
	showPreview: false,
	openedLimitWarningModal: false,
	visibleItemErrorBanner: false,
	cartMaxAmount: '',
	showRefundPartners: false,
	openedAddNoStockModal: false,
	openedStockModal: false,
	stockModalState: null,
	stockCart: null,
	preventClose: false,
	smallSnackbar: false,
}

export type CartStateContextType = InitialState & { showShoppingCart: boolean }

export interface CartProviderProps {
	readonly children: ReactNode
	readonly initialState?: Partial<CartStateContextType>
}

export type CartDispatch = Dispatch<Partial<InitialState>>

export const CartStateContext = createContext<CartStateContextType | undefined>(
	undefined
)

const CartDispatchContext = createContext<
	((newState: Partial<CartStateContextType>) => void) | undefined
>(undefined)

export const CartProvider = ({
	children,
	initialState = {},
}: CartProviderProps) => {
	const { country, pageType } = useMasterData()

	const defaultInitialState = {
		...DEFAULT_INITIAL_STATE,
		cartMaxAmount: conversion(country.shoppingCart.cartMaxAmount, country)
			.price,
	}

	const [state, setState] = useState<InitialState>({
		...defaultInitialState,
		...initialState,
	})

	const dispatch = useCallback((newState: Partial<CartStateContextType>) => {
		setState((prevState) => ({ ...prevState, ...newState }))
	}, [])

	const value = useMemo(
		() => ({
			...state,
			showShoppingCart: pageType === PAGE_TYPE_SHOPPING_CART,
		}),
		[state, pageType]
	)

	return (
		<CartStateContext.Provider value={value}>
			<CartDispatchContext.Provider value={dispatch}>
				{children}
			</CartDispatchContext.Provider>
		</CartStateContext.Provider>
	)
}

export function useCartState(): CartStateContextType {
	const context = useContext(CartStateContext)
	if (context === undefined) {
		throw new Error('useCartState must be used within a CartProvider')
	}
	return context
}

export function useCartDispatch() {
	const context = useContext(CartDispatchContext)
	if (context === undefined) {
		throw new Error('useCartDispatch must be used within a CartProvider')
	}
	return context
}
